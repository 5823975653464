import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import moment from "moment";
import storage from '../../../framework/src/StorageProvider';
import firebase from "firebase";

interface NotificationPayloadData {
  title: string;
  body: string;
  first_name: string;
  last_name: string;
  profile_image_url: string;
}

interface NotificationPayload {
  data: {
    payload: NotificationPayloadData | any; 
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  NaviagationPage: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  drawerItems: any;
  activeOption: string;
  token: string;
  anchorEl: null | HTMLElement;
  userId:string;
  userRoleId:string;
  firstName:string;
  lastName:string;
  profilePic:string;
  isSurveyNotificationOpen : boolean;
  substitute_Teacher_Survey :{
    shift: string;
    ages_worked_with: string;
    position: string;
    welcoming_program: string;
    prepared_for_job: string;
    return_to_program: string;
    feedback: string;
  } | null  ,
  admin_Survey : {
    shift: string;
    position: string;
    hire_again: string;
    feedback: string;
  } | null,
  isFormSubmited : boolean,
  successMessage : string 
  ErrorMessage:string,
  formIsEmpty:boolean,
  adminSurveyForm : any,
  teacherSurveyForm:any,
  surveyTitle : string,
  survey_job_id: number
  totalCredits:number;
  notificationTitle:string;
  notificationBody:string;
  isNotificationReceived:boolean;
  loginToken:string;
  notificationImage:string,
  fname : string,
  lname :string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  getProfileDataId: string = "";
  getProfileDataId2: string = "";
  getCreditsId:string="";
  setDeviceTokenid:string='';
  getSMS_PN_StatusId:string='';
  getSMS_N_StatusId:string='';
  getSMS_R_StatusId:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      activeOption: "Home",
      anchorEl: null,
      userId:'',
      userRoleId:localStorage.getItem("role")||'',
      firstName:'',
      lastName:'',
      profilePic:'',
      isSurveyNotificationOpen : false,
      substitute_Teacher_Survey : {
        shift: '',
        ages_worked_with: '',
        position: '',
        welcoming_program: '',
        prepared_for_job: '',
        return_to_program: '',
        feedback: ''
      },
      admin_Survey : {
        shift:"",
        position:"",
        hire_again:"",
        feedback :""
      },
      isFormSubmited:false,  
      successMessage : "",
      ErrorMessage : "",
      formIsEmpty:false,
      adminSurveyForm :[],
      teacherSurveyForm:[],
      surveyTitle :"",
      survey_job_id:0,
      totalCredits:0, 
      notificationTitle:'',
      notificationBody:'',
      isNotificationReceived:false,
      loginToken:'',
      notificationImage:"",
      fname : "",
      lname :""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.listenForMessages = this.listenForMessages.bind(this);
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      this.getStatus(responseJson , apiRequestCallId)
      if (apiRequestCallId== this.getProfileDataId2) {
        if (responseJson.errors && responseJson.errors[0].token === "Token has Expired") {
          storage.remove("loginToken")
          localStorage.removeItem('login-token')
        }
        else{
        const roleId=responseJson?.data?.attributes?.role?.id

        this.setState({
          userRoleId: roleId,
          firstName: responseJson.data.attributes.first_name.charAt(0).toUpperCase() + responseJson.data.attributes.first_name.slice(1),
          lastName: responseJson.data.attributes.last_name.charAt(0).toUpperCase(),
        });
        if(responseJson.data.attributes.profile_image==''){
          this.setState({profilePic:''})
        }else{
          this.setState({ profilePic: responseJson?.data?.attributes.profile_image.url})
        }
        storage.set('fistLetter',responseJson.data.attributes.first_name.charAt(0).toUpperCase())
      }
    }
      if(apiRequestCallId==this.getCreditsId){
        this.setState({totalCredits:responseJson.credits})
        storage.set('totalCredits',responseJson.credits)
      }
      this.setState({
        successMessage : responseJson.message
      }) 
      this.checkEndDate(responseJson.data)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  checkEndDate = (data: any) => {
    const index = 0;
    if (data[index]?.job_id) {
      const currentDate = moment().format('YYYY-MM-DD');
      const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
      const matchingJob = data.find((job: any) => (job.end_date === currentDate && job.is_submit === false) || (job.end_date === yesterday && job.is_submit === false));

      if (matchingJob) {
        const title = matchingJob.job_title ? matchingJob.job_title : matchingJob.substitute_name
        this.setState({
          surveyTitle: title,
          adminSurveyForm: matchingJob,
          survey_job_id: matchingJob.job_id
        })
        const endDate = moment(matchingJob?.end_date, 'YYYY-MM-DD')
        const hoursSinceEndDate = moment().diff(endDate, 'hours');
        this.setState({
          isSurveyNotificationOpen: hoursSinceEndDate <= 48
        });

      }
    }
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    const roleid =await storage.get("role")
    const fname = await storage.get("fname")
    const lname = await storage.get("lname")
    const profile = await storage.get("profileImage")
    this.setState({
      userRoleId: roleid,
      firstName: fname?.charAt(0).toUpperCase() + fname?.slice(1),
      lastName: lname?.charAt(0).toUpperCase(),
      profilePic: profile
    })
    this.getToken();
    this.getSMS_No_Status()
      this.getSMS_R_Status()
      this.getSMS_PN_Status()
    storage.remove('naviagteTo')
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.setState({loginToken:await storage.get('loginToken')})
    const token = await storage.get("loginToken")
    const userId=await storage.get('loginId')
    this.setState({ token: token, userId:userId })
    this.setState({ activeOption: this.props.NaviagationPage })
    this.getProfileData()
    
    const role:any = await storage.get("role")   
     
    if (role == 2) {
      this.getTeacherForm()
    }

    if (role == 1) {
      this.getAdminForm()
    }   
    this.getCredits()
    this.listenForMessages();
  }

  getStatus(responseJson:any , apiRequestCallId:string){
    switch (apiRequestCallId) {
      case this.getSMS_PN_StatusId:
        storage.set("isNotificationChecked" , responseJson?.allow_notification )
        break;
      case this.getSMS_R_StatusId:
        storage.set("isRecommendationsChecked" , responseJson?.sms_recommendation)
        break;
      case this.getSMS_N_StatusId:
        storage.set("isSmsNotiChecked" , responseJson?.allow_sms_notification)
        break;
      default:
        break;
    }
  }

  listenForMessages() {
    const messaging = firebase.messaging();

    messaging.onMessage((payload:NotificationPayload) => {
      const parsedPayload = payload?.data?.payload ? JSON.parse(payload.data.payload).data : {};
      const { title, body ,first_name ,last_name ,profile_image_url} = parsedPayload;
      if (Notification.permission === "granted") {
        this.setState({notificationTitle:title,notificationBody:body,isNotificationReceived:true , lname : last_name , fname:first_name , notificationImage:profile_image_url})
      } 
    });
  }
  
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isNotificationReceived && !prevState.isNotificationReceived) {
      setTimeout(() => {
        this.setState({ isNotificationReceived: false });
      }, 5000);
    }
  }
closeDialog=()=>{
  this.setState({ isNotificationReceived: false })
}
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleOptionClick = (option: string) => {
    this.setState({ activeOption: option });
  }
  handleLogin = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleSignup = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountRegistration'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }
  handleProfileSelect = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CustomisableUserProfiles'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
    this.setState({ anchorEl: null });
  }
  handleBack = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobCreated'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
    this.setState({ anchorEl: null })
  }
  handleHomClick = (home: string) => {
    storage.set("refresh" , true)
    this.setState({ activeOption: home })
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleChatClick = (chat: string) => {
    this.setState({ activeOption: chat })
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Chat'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleLogout = () => {
    storage.remove("loginToken")
    storage.remove("schoolid")
    storage.remove('role')
    localStorage.removeItem('login-token')
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleSavedJob = (savedjob: string) => {
    this.setState({ activeOption: savedjob })
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SavedJob'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleAppliedJobsApp = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'MyJobsApplication'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  getProfileData = () => {
    const header = {
      token: this.state.token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataId2 = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${this.state.userId}/show_profile`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  handleTeamClick=(saved:string)=>{
    this.setState({ activeOption: saved })
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SavedTeachers'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleAddPost=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PostCreation'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  navigateToSetting=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'Settings2');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  } 
  
  handleSurveyNotificationClose=()=>{
    this.setState({
      isSurveyNotificationOpen : false,
      isFormSubmited : false
    })
  }

  handleOpen = ()=>{
    this.setState({
      isSurveyNotificationOpen : true
    })
  }

handleTeacherSurveySubmission = async () => {
    
      const header = {
        "Content-Type": 'application/json',
        token: await storage.get("loginToken"),
      };
  
      const setIntroMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_surveys/survey_questions`
      );
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({...this.state.substitute_Teacher_Survey ,job_creation_id : this.state.survey_job_id})
      )
  
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
      this.setState({
        isSurveyNotificationOpen :false, 
        isFormSubmited : true,
        substitute_Teacher_Survey : {
          ages_worked_with : "",
          feedback:"",
          position:"",
          prepared_for_job:"",
          return_to_program:"",
          shift:"",
          welcoming_program:""
        }
      })
      await storage.set("isSubmited" , true)

}

handleTextFieldForTeacherChange = (value : string , name : string)=>{
    this.setState((prevState:any) => ({
      substitute_Teacher_Survey: {
        ...prevState.substitute_Teacher_Survey,
        [name]: value,
      },
    }));
}

handleTextFieldForAdminChange = (value : string , name : string)=>{
      this.setState((prevState:any) => ({
        admin_Survey: {
          ...prevState.admin_Survey,
          [name]: value
        }
      }));
}

handleAdminSurveySubmission = async () => { 
   
    const header = {
      "Content-Type": 'application/json',
      token: await storage.get("loginToken"),
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_surveys/survey_questions/school_admin_survey_questions`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      
      JSON.stringify({...this.state.admin_Survey ,job_creation_id : this.state.survey_job_id})
    )

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    this.setState({
      isSurveyNotificationOpen :false,
      isFormSubmited : true,
      admin_Survey : {
        hire_again:"",
        feedback:"",
        position:"",
        shift:"",
      }
    })
    await storage.set("isSubmited" , true)
}

getAdminForm = async () => { 
   
  const header = {
    token: this.state.token,
  };

  const getTeacherForm = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getProfileDataId = getTeacherForm.messageId;

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_surveys/survey_questions/school_admin_survey_form`
  );

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `GET`
  );

  runEngine.sendMessage(getTeacherForm.id, getTeacherForm);
}

getTeacherForm = async () => { 
   
  const header = {
    token: this.state.token,
  };

  const getTeacherForm = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getProfileDataId = getTeacherForm.messageId;

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_surveys/survey_questions/substitute_teachers_jobs`
  );

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `GET`
  );

  runEngine.sendMessage(getTeacherForm.id, getTeacherForm);
}
getCredits=()=>{
  const header = {
    token: this.state.token,
    "Content-Type": 'application/json',
  };

  const getTeacherForm = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getCreditsId = getTeacherForm.messageId;

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_joblisting/job_creations/user_credits`
  );

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  getTeacherForm.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `GET`
  );

  runEngine.sendMessage(getTeacherForm.id, getTeacherForm);
}
viewTrancastions=()=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(getName(MessageEnum.NavigationTargetMessage),'Transaction');
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}
navigatePrivacyFaq = (pageName: string) => {
  this.setState({ activeOption: 'FAQs' })
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    'Faq'
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), { ...this.props, pageName })
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), { pageName });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}
navigateAboutus=()=>{
  this.setState({ activeOption: 'About' })
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    'AboutUs'
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

async getSMS_PN_Status(){
  const token = await storage.get("loginToken")
  const header = {
    token: token,
  };

  const getSMS_RStatus = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSMS_PN_StatusId = getSMS_RStatus.messageId;

  getSMS_RStatus.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_settings/settings/show_push_notification`
  );

  getSMS_RStatus.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  getSMS_RStatus.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `GET`
  );

  runEngine.sendMessage(getSMS_RStatus.id, getSMS_RStatus);
}
async getSMS_No_Status(){
const token = await storage.get("loginToken")
const header = {
  token: token,
};

const getSMS_NoStatus = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getSMS_N_StatusId = getSMS_NoStatus.messageId;

getSMS_NoStatus.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `/bx_block_settings/settings/show_sms_notification`
);

getSMS_NoStatus.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

getSMS_NoStatus.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  `GET`
);

runEngine.sendMessage(getSMS_NoStatus.id, getSMS_NoStatus);
}
async getSMS_R_Status(){
const token = await storage.get("loginToken")
const header = {
  token: token,
};

const getSMS_PnStatus = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getSMS_R_StatusId = getSMS_PnStatus.messageId;

getSMS_PnStatus.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `/bx_block_settings/settings/show_recommendation_notification`
);

getSMS_PnStatus.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

getSMS_PnStatus.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  `GET`
);

runEngine.sendMessage(getSMS_PnStatus.id, getSMS_PnStatus);
}

goToNotification = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),'NotificationList');
  
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage));
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  
  this.send(message);
}
  // Customizable Area End
}
