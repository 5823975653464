import React from "react";

// Customizable Area Start
import {
  Box,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { notesIcon, uploadDocumentIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const UploadDocumentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px 12px 12px',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  boxShadow: '0px 2px 8px 0px #00000014',
  marginBottom: '16px',
});

const UploadDocumentText = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  color: '#1E293B',
  marginLeft: '12px',
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
  },
});
// Customizable Area End

import DocumentDistributionController, {
  Props,
  configJSON,
} from "./DocumentDistributionController";

export default class DocumentDistribution extends DocumentDistributionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { filename, defaultName, onChangeName, handleDocumentChange } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <UploadDocumentWrapper>
            <Box style={webStyles.leftBox}>
              <img src={notesIcon} alt="notes_icon" />
              <UploadDocumentText>{filename?.name || defaultName}</UploadDocumentText>
            </Box>
            <label htmlFor={defaultName} className="edit-icon">
              <img style={webStyles.uploadDocIcon} src={uploadDocumentIcon} alt="upload_icon" />
            </label>
            <input id={defaultName} type="file" accept=".png, .jpg, .jpeg, .pdf, .doc"  onChange={handleDocumentChange(onChangeName)} style={webStyles.displayNone} />
          </UploadDocumentWrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  boxMargin: { marginTop: "20px" },
  leftBox: {
    display: 'flex',
    alignItems: 'center',
  },
  displayNone: {display: 'none'},
  uploadDocIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
};
// Customizable Area End
