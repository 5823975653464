import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface Messages {
  message_sid: string,
  type: string
  conversation_sid: string,
  body: string,
  date_updated: string,
  job_id : number,
  job_offer: boolean,
  accept: boolean,
  decline: boolean,
  date_created: string,
  created_at: string,
  read: boolean,
}
interface Contact {
  first_name: string;
  last_name: string;
  conversation_sid: string;
  email: string;
  role: {
    id: number;
  };
  organization: string;
  profile_image: {
    url: string
  }
}
export interface ConversationList {
  last_message: string
  last_message_time: string
  receiver_unread_count: number;
  conversation_sid: string
  other_party_details: {
    data: {

      attributes:
      {
        first_name: string
        last_name: string
        email: string
        profile_image: {
          url: string,
        },
        organization: string,
        role: {
          id: number,
        }
      }
    }
  }
}
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  messages: Messages[];
  selectedContact: Contact | null;
  selectedContactId: number | null;
  typedMessage: string;
  conversationList: ConversationList[];
  openNoChats: boolean;
  messagesAvailable: boolean;
  isMobileView: boolean;
  roleName: string;
  isOffered : boolean;
  isAccepted:boolean;
  isDeclained:boolean;
  chatAnchorEl: null | HTMLElement;
  selectedItem: string | null;
  selectedItemId : number ;
  menuItems: [
    {
      value:number,
      title:string
    }
  ];
  selectedFile:File|null;
  errorMessage:string;
  role:string;
  loader:boolean;
  disable:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getConversationListID: string = '';
  getAllFetchmsg: string = '';
  createmsgId: string = '';
  setReadId: string = "";
  markUsUnreadApiId: string = "";
  getProfileDataId: string = "";
  fileInput: HTMLInputElement | null = null;
  getJobSId:string=''
  scrollEnd = React.createRef<HTMLInputElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      messages: [

      ],
      selectedContact: null,
      selectedContactId: null,
      typedMessage: '',
      conversationList: [],
      openNoChats: false,
      messagesAvailable: false,
      isMobileView: window.innerWidth <= 571,
      roleName: '',
      isOffered:false,
      isAccepted:false,
      isDeclained:false,
      chatAnchorEl: null,
      selectedItem: null,
      menuItems:[{value:0,title:""}],
      selectedItemId : 0,
      selectedFile:null,
      errorMessage:'',
      role:'',
      loader:false,
      disable:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.setState({loader:true})
    const cId =await  storage.get("conversation_sid")
    const role = await storage.get('role') 
    this.setState({
      selectedContact :{
        conversation_sid:cId,
        first_name:'',
        last_name:'',
        email:'',
        organization:"",
        profile_image:{url:''},
        role:{id:0}
      },
      role:role
    })
    this.getConversationList()
    window.addEventListener("resize", this.handleResize);
    this.getProfileData()
    this.getJobListOfAdmin()
    this.scrollToEnd(this.scrollEnd)
  }
  async componentWillUnmount() {
    this.scrollToEnd(this.scrollEnd)
    window.removeEventListener("resize", this.handleResize);
  }

  scrollToEnd = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;  
    }
  };
  
  handleResize = () => {
    if (this.state.role == '1') {
      this.setState({ isMobileView: window.innerWidth <= 850 });
    }
    else{
      this.setState({ isMobileView: window.innerWidth <= 571});
    }
  };

  navigateBackToList = () => {
    this.setState({ selectedContact: null });
  };
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleData(responseJson , apiRequestCallId)
  
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const accountId: number = messageData?.meta?.id;
      this.setState({ accountId });
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
      
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
    this.otherApis(apiRequestCallId, responseJson)
    this.profileApi(apiRequestCallId, responseJson)
    
  }
  handleData =(responseJson:any , id:any)=>{
    if (id == this.getJobSId) {
      const jobs = responseJson?.jobs;
  
      if (jobs && jobs?.length > 0) {
        this.setState({
          menuItems: jobs.map((item: any) => ({
            title: item?.job_title || "Untitled Job",
            value: item?.id || 0,
          })),
          disable:false
        });
      } else {
        this.setState({
          menuItems: [{ title: "No Data Found", value: 0 }],
          disable:true
        });
      }
    }
  }
  otherApis = (apiRequestCallId: string, responseJson: {
    status: number; conversation: any; data: any;
  }) => {
    if (apiRequestCallId == this.getConversationListID) {
      this.setState({ loader: true });
      this.getFecthAllmsgList()
      if (responseJson.status == 500) {
        this.setState({ openNoChats: true })
      }
      this.setState({ conversationList: responseJson.conversation })
      this.handleSelectConversation()
      if (this.state.conversationList.length <= 0) {
        this.setState({ openNoChats: true })
      }
      else { this.setState({ openNoChats: false }) }
      this.setState({ loader: false });
    }
    if (apiRequestCallId === this.createmsgId) {
      this.getFecthAllmsgList()
      this.setState({ typedMessage: "",selectedFile:null })
    }
    if (apiRequestCallId === this.getAllFetchmsg) {
      
      this.setState({ messages: responseJson.data })
      if (this.state.messages?.length <= 0) { this.setState({ messagesAvailable: false }) }
      else { this.setState({ messagesAvailable: true });
      this.scrollToEnd(this.scrollEnd)
    }
    }
  }
  profileApi = (apiRequestCallId: string, responseJson: { data: { attributes: { role: { id: number } }; }; }) => {
    if (apiRequestCallId == this.getProfileDataId) {
      const apiData = responseJson.data.attributes
      if (apiData.role.id == 1) {
        this.setState({ roleName: "substitute_teacher_email" })
      }
      if (apiData.role.id == 2) {
        this.setState({ roleName: "school_admin_email" })
      }
    }
  }
  navigateToHome = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleContactClick = (contact: {
    first_name: string, last_name: string, conversation_sid: string, email: string, receiver_unread_count?: number, organization: string, role: { id: number },
    profile_image: { url: string }
  }, index: number) => {
    const { receiver_unread_count = 0 } = contact
    this.setState({
      selectedContact: contact,
      selectedContactId: index,
      isOffered :false
    });

    this.getFecthAllmsgList(contact?.conversation_sid)
    if (receiver_unread_count != 0) {

      this.markUsRead()
      this.setState(prevState => {
        const updatedConversationList = [...prevState.conversationList];
        updatedConversationList[index] = { ...updatedConversationList[index], receiver_unread_count: 0, };
        return { conversationList: updatedConversationList };
      });
    }
  };
  enteredText = (event: string) => {
    this.setState({ typedMessage: event ,selectedFile: null})
  }
  sendMessageClick = () => {
    if (this.state.typedMessage || this.state.selectedFile) {
      this.createMsg()
    }
  }

  getConversationList = async () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: await storage.get("loginToken"),
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getConversationListID = setIntroMsg.messageId;
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chats/conversation_list`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  getFecthAllmsgList = async (id?:string) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: await storage.get("loginToken"),
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllFetchmsg = setIntroMsg.messageId;
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_chat/chats/twilio_message_by_conversation_sid?conversation_sid=${id||this.state.selectedContact?.conversation_sid}`
    );
    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  createMsg = async () => {
    const header = {
      token: await storage.get("loginToken"),
    };
    const formData = new FormData();
    formData.append("conversation_sid", String(this.state.selectedContact?.conversation_sid));
    if (this.state.selectedFile) {
      formData.append("files[]", this.state.selectedFile);
    } else {
      formData.append("message", this.state.typedMessage);
    }
    formData.append(this.state.roleName, String(this.state.selectedContact?.email));

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createmsgId = setIntroMsg.messageId;

    setIntroMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_chat/chats/create_message`);

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

  }
  markUsRead = async () => {
    const header = {
      token: await storage.get("loginToken"),
    };

    const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.markUsUnreadApiId = setIntroMsg.messageId;

    setIntroMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_chat/chats/mark_messages_as_read?conversation_sid=${this.state.selectedContact?.conversation_sid}`);

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }

  handleSelectConversation = async () => {
    const conversationId = await storage.get('conversation_sid');
    const selectedContact = this.state.conversationList
      .map((conv) => ({
        first_name: conv.other_party_details.data.attributes.first_name,
        last_name: conv.other_party_details.data.attributes.last_name,
        conversation_sid: conv.conversation_sid,
        email: conv.other_party_details.data.attributes.email,
        organization: conv.other_party_details.data.attributes.organization,
        role: { id: conv.other_party_details.data.attributes.role.id },
        profile_image: { url: conv.other_party_details.data.attributes.profile_image.url }
      }))
      .find((contact) => contact.conversation_sid === conversationId) || null;

    this.setState({ selectedContact }, () => {
      if (this.state.selectedContact) {
        this.getFecthAllmsgList();
      }
    });
  };

  handleKeyDown = (event: { key: string; preventDefault: () => void; }) => {
    if (event.key === 'Enter') {
      this.sendMessageClick();
    }
  };

  getProfileData = async () => {
    const loginId = await storage.get("loginId")
    const token = await storage.get("loginToken")
    const header = {
      token: token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataId = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${loginId}/show_profile`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  shortString = (value: string) => {
    if (value.includes('data:application/pdf;base64,')) {
      const [fileName] = value.split(' ', 1);
      return fileName
    }
    else if (value.includes('data:image/')) {
      const [fileName] = value.split(' ', 1);
      return fileName
    }
    else if (value.length <= 40) {
      return value;
    }
    return value.slice(0, 45) + "...";
  };
 
handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const MAX_FILE_SIZE = 80 * 1024 * 1024;
  const file = event.target.files?.[0];
  if (file) {
    if (file.size > MAX_FILE_SIZE) {
      this.setState({
        errorMessage: 'File size should be less than 80MB',
        selectedFile: null,
      });
    } else {
      this.setState({
        selectedFile: file,
        typedMessage: '',
        errorMessage: '',
      });
    }
    if (this.fileInput) {
      this.fileInput.value = '';
    }
  }
};
  handleFileInputClick = () => {
    if (this.fileInput) {
      this.fileInput.click();
    }
  };


  getJobListOfAdmin = async () => {
    const token = await storage.get("loginToken")
    const header = {
      token: token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJobSId = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chats/school_admin_jobs`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  handleOfferJob =()=>{
    this.setState({
      isOffered:true
    })
  }

  handleCompleteOfferJob = ()=>{
    this.setState({
      isOffered:false
    })
  }

  handleAcceptJob = (job_id:number , job_title:string)=>{
    this.setState({
      isAccepted:true
    })
    this.createMsgForAcceptDecline(true , true , false ,job_id , job_title)
  }

  handleDeclainJob = (job_id:number  , job_title:string) =>{
    this.setState({
      isDeclained:true
    })
    this.createMsgForAcceptDecline(true , false , true ,job_id , job_title)
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ chatAnchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ chatAnchorEl: null });
  };

  handleMenuItemClick = (item: string , id:number) => {
    
    this.setState({
      selectedItem: item,
      chatAnchorEl: null ,
      isOffered:true,
      selectedItemId :id
    });
    if (this.state.typedMessage === '') {
      this.createMsgForAcceptDecline(true ,false ,false , id , item)
    }
  };

  createMsgForAcceptDecline = async (isOffered:any, accept:any, decline:any , job_id : number , job_title:string) => {
    const header = {
      token: await storage.get("loginToken"),
    };
    const formData = new FormData();
    formData.append("conversation_sid", String(this.state.selectedContact?.conversation_sid));
    formData.append("message", this.state.typedMessage);
    formData.append(this.state.roleName, String(this.state.selectedContact?.email));
    formData.append("job_offer" , String(true))
    formData.append("job_id" , String(job_id))
    formData.append("accept" , String(accept))
    formData.append("decline" , String(decline))
    formData.append("job_title" , String(job_title))        
    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createmsgId = setIntroMsg.messageId;

    setIntroMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_chat/chats/create_message`);

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    setIntroMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

  }

  // Customizable Area End
}
