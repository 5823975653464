// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DataStorage from "../../blocks/datastorage/src/DataStorage";
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen.web";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Dataencryption2 from "../../blocks/dataencryption2/src/Dataencryption2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Matchingalgorithm4 from "../../blocks/matchingalgorithm4/src/Matchingalgorithm4";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import Verification from "../../blocks/email-account-registration/src/Verification.web"
import OtpConfirmation from "../../blocks/email-account-registration/src/OtpConfirmation.web"
import VerificationSentPending from "../../blocks/email-account-registration/src/VerificationSentPending.web"
import JobListing from "../../blocks/joblisting/src/JobListing.web"
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import JobListingDetails from "../../blocks/joblisting/src/JobListingDetails.web"
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions.web'
import SavedJob from '../../blocks/joblisting/src/SavedJob.web'
import MyJobsApplication from '../../blocks/joblisting/src/MyJobsApplication.web'
import PrivacyFaq from '../../blocks/settings2/src/PrivacyFaq.web'
import InvitefriendViaEmail from '../../blocks/invitefriends/src/Invitefriends.web'
import Payments from '../../blocks/postcreation/src/Payments.web'
import Transaction from "../../blocks/postcreation/src/Transaction.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationList from '../../blocks/notificationsettings/src/NotificationList.web'
import PostCreated from "../../blocks/postcreation/src/PostCreated.web";
import JobDetails from "../../blocks/postcreation/src/JobDetails.web"
import PostEdit from "../../blocks/postcreation/src/PostEdit.web"
import {NoInternet} from "../../components/src/NoInternet.web"  
import Faq from "../../blocks/settings2/src/Faq.web" 

const routeMap = {

AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
ResetPassword:{
  component:ResetPassword,
 path:"/ResetPassword"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Dataencryption2:{
 component:Dataencryption2,
path:"/Dataencryption2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Matchingalgorithm4:{
 component:Matchingalgorithm4,
path:"/Matchingalgorithm4"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
PostEdit:{
  component:PostEdit,
 path:"/PostEdit"},
Posts:{
 component:Posts,
path:"/Posts"},
JobCreated:{
  component:PostCreated,
 path:"/JobCreated"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
JobDetails:{
  component:JobDetails,
 path:"/JobDetails"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
CustomForm:{
  component:CustomForm,
  path:"/CustomForm"
},
Verification:{
  component:Verification,
  path:"/Verification"
},
OtpConfirmation:{
  component:OtpConfirmation,
  path:"/OtpConfirmation"
},
VerificationSentPending:{
  component:VerificationSentPending,
  path:"/VerificationSentPending"
},
JobListing:{
  component:JobListing,
  path:"/JobListing"
},
TeacherListing:{
  component:JobListing,
  path:"/TeacherListing"
},
CustomisableUserProfiles:{
  component:CustomisableUserProfiles,
  path:"/CustomisableUserProfiles"
},
JobListingDetails: {
  component: JobListingDetails,
  path: "/JobListingDetails"
},
TeacherListingDetails: {
  component: JobListingDetails,
  path: "/TeacherListingDetails"
},
SavedJob:{
  component:SavedJob,
  path:"/SavedJob"
},
SavedTeachers:{
  component:SavedJob,
  path:"/SavedTeachers"
},
MyJobsApplication:{
  component:MyJobsApplication,
  path:"/MyJobsApplication"
},
PrivacyFaq:{
  component:PrivacyFaq,
  path:'/PrivacyFaq'
},
InvitefriendViaEmail:{
  component:Invitefriends,
  path:'/InvitefriendViaEmail'
},
Faq:{
  component:Faq,
  path:'/Faq'
},
Payments:{
  component:Payments,
  path:'/Payments'
},
Transaction:{
  component:Transaction,
  path:'/Transaction'
},
AboutUs:{
  component:Dashboard,
  path:'/AboutUs'
},
NotificationList:{
  component:NotificationList,
  path:'/NotificationList'
},

  Home: {
    component: JobListing,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyCfWklVPPQQrqJFIczCDuSuoLpeyJqrBNM",
  authDomain: "palmettochildcareconnect.firebaseapp.com",
  projectId: "palmettochildcareconnect",
  storageBucket: "palmettochildcareconnect.appspot.com",
  messagingSenderId: "492921535171",
  appId: "1:492921535171:web:514fe9dc3f6ec5c0b34076",
  measurementId: "G-T8J25ME87N"
});

class App extends Component {
   
  render() {
   const scrollView=()=>{
      window.scrollTo(0,0)
    }
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {scrollView()}
        <ToastContainer/>
        <NoInternet/>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;