// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  CardMedia,
  Badge,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { backIcon, backgroundMobile, backgroundWeb, backwardIcon, editProfileIcon, logo, notesIcon, uploadDocumentIcon } from "./assets";

import CustomFormController, {
  Props,
  Dropdown,
  configJSON,
} from "./CustomFormController.web";
import { KeyboardArrowDown } from "@material-ui/icons";
import CheckBoxList from "../../../components/src/CheckBoxList";
import RadioList from "../../../components/src/RadioList";
import DocumentDistribution from "../../documentdistribution/src/DocumentDistribution.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const MainContainerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  alignItems: 'center',
  background: `url(${backgroundWeb})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  paddingBottom : "150px",
  '@media only screen and (max-width: 474px)': {
    backgroundSize: 'cover',
    padding: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    background: `url(${backgroundMobile})`
  },
});

const MainHeading = styled(Typography)({
  color: '#1E293B',
  fontSize: '30px',
  marginTop: '56px',
  fontWeight: 700,
  lineHeight: '40px',
  fontFamily:"Inter",
  textAlign: 'left' as 'left',
  '@media only screen and (max-width: 502px)': {
    fontSize: '24px',
    lineHeight: '32px',
  },
})
const SubHeading = styled(Typography)({
  color: '#334155',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  fontFamily:"Inter",
  textAlign: 'left' as 'left',
  marginBottom: '32px',
  '@media only screen and (max-width: 502px)': {
    fontSize: '14px',
    lineHeight: '22px',
  },
})
const InputField = styled(TextField)({
  marginBottom: '24px',
  '@media only screen and (max-width: 414px)': {
    marginBottom: '16px',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: '#FFFFFF',
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: '#FFFFFF',
    height: '56px',
    margin: 'auto',
    borderRadius: '8px',
    fontSize: '16px',
    fontFamily: 'Inter',
    '@media only screen and (max-width: 414px)': {
      fontSize: '14px',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline': {
    borderColor: '#FFFFFF',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#FFFFFF',
    borderWidth: '1px',
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 16px 10px 16px',
    color: '#1E293B',
    fontFamily: 'Inter',
    '&::placeholder': {
      color: '#94A3B8',
      fontSize: '16px',
      fontFamily: 'Inter',
      '@media only screen and (max-width: 414px)': {
        fontSize: '14px',
      },
    },
    '&:not(:placeholder-shown)': {
      color: '#1E293B',
      fontFamily: 'Inter',
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #CBD5E1',
  },
});
const DropDown = styled(Select)({
  marginBottom: '24px',
  background: '#fff',
  height: '56px',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#FFFFFF',
    borderWidth: '1px',
    borderRadius: '8px',
  },
  '& :before': {
    borderColor: 'white',
  },
  '& :after': {
    borderColor: 'white',
  },
  '& :not(.Mui-disabled):hover::before': {
    borderColor: 'white',
  },
});
const FormBox = styled(Box)({
  width: '588px',
  '@media only screen and (max-width: 878px)': {
    width: '498px'
  },
  '@media only screen and (max-width: 664px)': {
    width: '410px'
  },
  '@media only screen and (max-width: 502px)': {
    width: '327px'
  },
})
const BackWardBox = styled(Box)({
  display: 'inline-flex',
  gap: "8px",
  position: "absolute",
  top: 60,
  left: 120,
  '@media only screen and (max-width: 600px)': {
    left: 103
  },
  '@media only screen and (max-width: 540px)': {
    left: 45
  }
})
const BackTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily:"Inter",
  lineHeight: "24px",
  color: "#1E293B",
  '@media only screen and (max-width: 540px)': {
    display: 'none'
  }
})


const ContainerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: `url(${backgroundWeb})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
paddingBottom: '150px',
  "@media only screen and (max-width: 414px)": {
    background: `url(${backgroundMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

export const BackIconBox = styled(Box)({
  display: 'flex',
  gap: "6px",
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute' as 'absolute',
  top: 64,
  left: 120,
  cursor: 'pointer',
  "@media only screen and (max-width: 768px)": {
    top: 48,
    left: 60,
  },
  "@media only screen and (max-width: 628px)": {
    top: 28,
    left: 30,
  },
  "@media only screen and (max-width: 414px)": {
    top: 18,
    left: 24,
  },
});

const FormContainer = styled(Box)({
  width:'588px',
  "@media only screen and (max-width: 768px)": {
    width:'500px',
  },
  "@media only screen and (max-width: 628px)": {
    width:'400px',
  },
  "@media only screen and (max-width: 414px)": {
    width: '327px'
  },
});

const WebLogoImage = styled(CardMedia)({
  height: '90px',
  width: '260.71px',
  display: 'block',
  margin: '130px 0 56px 0',
  "@media only screen and (max-width: 628px)": {
    margin: '100px 0 52px 0',
  },
  "@media only screen and (max-width: 414px)": {
    margin: '85px 0 48px 0',
  },
});

const PageHeading = styled(Typography)({
  color: '#1E293B',
  fontFamily: 'Inter',
  fontSize: '30px',
  fontWeight: 700,
  textAlign: 'left',
  width: '100%',
  lineHeight: '40px',
  marginBottom: '8px',
  "@media only screen and (max-width: 798px)": {
    fontSize: '26px',
    marginBottom: '8px',
    lineHeight: '28px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const PageSubHeading = styled(Typography)({
  color: '#334155',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'left',
  lineHeight: '24px',
  marginBottom: '32px',
  "@media only screen and (max-width: 798px)": {
    fontSize: '14px',
    lineHeight: '16px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const ProfilePictureHeading = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 700,
  color: '#334155',
  marginBottom: '16px',
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const ProfileAvatarWrapper = styled(Box)({
  textAlign: 'center',
  marginBottom: '48px',
});

const ProfileAvatar = styled(Avatar)({
  height: '156px',
  width: '156px',
  border: '4px solid #CBD5E1',
  backgroundColor: 'white',
  color: '#94A3B8',
  fontFamily: 'Inter',
  fontSize: '56px',
  fontWeight: 600,
  "@media only screen and (max-width: 414px)": {
    height: '132px',
    width: '132px',
  },
});

const InputsLabel = styled(Box)({
  color: '#334155',
  fontFamily:"Inter",
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left' as 'left',
  marginBottom: '6px',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
    marginBottom: '4px',
  },
});

const UploadDocumentLabel = styled(Box)({
  color: '#334155',
  fontFamily:"Inter",
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left' as 'left',
  marginBottom: '24px',
  marginTop: '24px',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
    marginBottom: '16px',
  },
});

const InputsLabelAboutUs = styled(Box)({
  fontSize: '16px',
  fontFamily:"Inter",
  fontWeight: 700,
  lineHeight: '24px',
  marginBottom: '6px',
  textAlign: 'left' as 'left',
  marginTop: '40px',
  color: '#334155',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
    marginBottom: '4px',
    marginTop: '24px',
  },
});

const TravelMainBox = styled(Box)({
  display: 'flex',
  margin: '24px 0 24px 0',
  "@media only screen and (max-width: 798px)": {
    display: 'block',
    margin: '12px 0 0 0',
  },
})

const TravelLeftBox = styled(Box)({
  width: '306px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 700,
  color: '#334155',
  "@media only screen and (max-width: 798px)": {
    marginBottom: '6px',
  },
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
  },
})

const TravelRightBox = styled(Box)({
  
});

const UploadDocumentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px 12px 12px',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  boxShadow: '0px 2px 8px 0px #00000014',
  marginBottom: '16px',
});

const UploadDocumentText = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  color: '#1E293B',
  marginLeft: '12px',
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
  },
});

const ContinueButtonBox = styled(Box)({
  width: '588px',
  height: '56px',
  marginTop: '40px',
  "@media only screen and (max-width: 768px)": {
    width:'500px',
  },
  "@media only screen and (max-width: 628px)": {
    width:'400px',
    marginTop: '32px',
  },
  "@media only screen and (max-width: 414px)": {
    width: '327px',
    marginTop: '24px',
  },
});

const ErrorBox = styled(Box)({
  color: '#DC2626',
    height: '18px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: "18px",
    marginTop:"-24px",
    marginBottom:"10px",
    
  "@media only screen and (max-width: 414px)": {
    marginTop:"-16px"
  },

})

const webStyles = {
  title: {
    color: '#334155',
    fontFamily:"Inter",
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'left' as 'left',
    marginBottom: '6px'
  },
  createBtn: {
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    fontFamily:"Inter",
    textTransform: 'none' as 'none',
    background: '#395169',
    height: '56px',
    borderRadius: '8px',
    marginTop: '48px',
    widht: '100%',
    marginBottom: '96px'
  },
  phoneBox: {
    display: "flex",
    gap: "16px"
  },
  popup: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FEE2E2",
    color: "DC2626",
    borderRadius: "4px",
    height: "42px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily:"Inter",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderLeft:"4px solid #DC2626"
  },
  backIcon: {
    height: '24px',
    width: '24px',
    marginRight: '6px',
  },
  backIconTxt: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
  },
  continueButton: {
    border: "none",
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundColor: '#395169',
    padding: '16px',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
  },
  continueButtonDisabled: {
    border: "none",
    width: '100%',
    height: '100%',
    cursor: 'not-allowed',
    backgroundColor: '#979090',
    padding: '16px',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
  },
  uploadDocIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  errorMessage: {
    color: '#DC2626',
    width: '384px',
    height: '18px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: "18px",
    marginTop:"-24px",
    marginBottom:"24px"
    

  }
  
};
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkColorCondition = (state: string, value: string) => {
    if(state === value){
      return '#A6B0BF96'
    }
    return '#1E293B'
  }
  getDropdowns = (key:string)=>{
    const dropDowns:any = {
      programType  : ["Family","Group Family Center","School Age"],
      roles : ["Teacher","Director","Hiring Manager"]
    }
    return dropDowns[key]
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      this.state.selectedRole == 1 ?
      <MainContainerBox>
        <FormBox>
          <BackIconBox data-test-id="handleBack" onClick={this.handleback}>
            <ArrowBackIosIcon />
            <BackTypography>Back</BackTypography>
          </BackIconBox>
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '130px' }}>
          <img src={logo} width={260} height={90}/>
          </Box>
          <MainHeading>{configJSON.mainHeading}</MainHeading>
          <SubHeading>{configJSON.subHeading}</SubHeading>
          <Typography style={webStyles.title}>{configJSON.memberId}</Typography>
          <InputField
            data-test-id="memberId"
            value={this.state.memberId}
            fullWidth variant='outlined'
            onBlur={(event) => {
              this.onValueChange('memberId', event.target.value);
            }}
            placeholder='Enter member id'
            onChange={(event) => {
              
              this.onValueChange('memberId', event.target.value);
            }}
            />
             <ErrorBox>{this.state.memberIdError}</ErrorBox>
          <Typography style={webStyles.title}>{configJSON.firstName}</Typography>
          <InputField
            data-test-id="firstName"
            value={this.state.firstName}
            fullWidth variant='outlined'
            placeholder='Enter first name'
            onBlur={(event) => {
              this.onValueChange('firstName', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('firstName', event.target.value);
            }}
          />
         <ErrorBox>{this.state.firstNameError}</ErrorBox>

          <Typography style={webStyles.title}>{configJSON.secondName}</Typography>
          <InputField fullWidth variant='outlined' placeholder='Enter last name'
            value={this.state.lastName}
            data-test-id="lastName"
            onBlur={(event) => {
              this.onValueChange('lastName', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('lastName', event.target.value);
            }} />
          <ErrorBox>{this.state.lastNameError}</ErrorBox>

          <Typography style={webStyles.title}>{configJSON.organisation}</Typography>
          <InputField fullWidth variant='outlined' placeholder='Enter organization'
            value={this.state.organization}
            data-test-id="organization"
            onBlur={(event) => {
              this.onValueChange('organization', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('organization', event.target.value);
            }} />
          <ErrorBox>{this.state.organizationError}</ErrorBox>

          <Typography style={webStyles.title}>{configJSON.street}</Typography>
          <InputField fullWidth variant='outlined' placeholder='Enter street address'
            value={this.state.street}
            data-test-id="street"
            onBlur={(event) => {
              this.onValueChange('street', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('street', event.target.value);
            }} />
          <ErrorBox >{this.state.streetError}</ErrorBox>
  
          <Typography style={webStyles.title}>{configJSON.city}</Typography>
          <InputField fullWidth variant='outlined' placeholder='Enter city'
            value={this.state.city}
            data-test-id="city"
            onBlur={(event) => {
              this.onValueChange('city', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('city', event.target.value);
            }} />
           <ErrorBox>{this.state.cityError}</ErrorBox>

          <Typography style={webStyles.title}>{configJSON.state}</Typography>
          <InputField fullWidth variant='outlined' placeholder='Enter state'
            data-test-id="state"
            value={this.state.state}
            onBlur={(event) => {
              this.onValueChange('state', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('state', event.target.value );
            }} />
           <ErrorBox>{this.state.stateError}</ErrorBox>
          
          <Typography style={webStyles.title}>{configJSON.zipcode}</Typography>
          <InputField fullWidth variant='outlined' placeholder='Enter zip code'
            data-test-id="zipCode"
            value={this.state.zipCode}
            onBlur={(event) => {
              this.onValueChange('zipCode', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('zipCode', event.target.value);
            }} />
           <ErrorBox >{this.state.zipCodeError}</ErrorBox>
         
          <Typography style={webStyles.title}>{configJSON.Country}</Typography>
          <DropDown fullWidth variant='outlined' placeholder='Select country' IconComponent={KeyboardArrowDown}
            value={this.state.country}
            data-test-id="country"
            onBlur={(event) => {
              this.onValueChange('country', event.target.value);
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            onChange={(event) => {
              this.onValueChange('country', event.target.value);
            }}
            style={{ color: this.checkColorCondition(this.state.country, 'Select country') }}
          >
            <MenuItem style={{ display: 'none' }} value='Select country'>Select country</MenuItem>
            {this.state.countryList.map((country) => (
              <MenuItem key={country} value={country}>{country}</MenuItem>
            ))}
          </DropDown>
          <Typography style={webStyles.title}>{configJSON.phone}</Typography>
          <Box style={webStyles.phoneBox}>
            <DropDown variant='outlined' IconComponent={KeyboardArrowDown} data-test-id="phoneCode" value={this.state.phoneCode} style={{ width: "100%"  , maxWidth:"113px"}}
              onChange={(event) => {
                this.onValueChange('phoneCode', event.target.value);
              }}
              onBlur={(event) => {
                this.onValueChange('phoneCode', event.target.value);
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}>
              {this.state.phoneCodeList.map((code) => (
                <MenuItem key={code} value={code}>{code}</MenuItem>
              ))}
            </DropDown>
            <div style={{width:"100%"}}>
            <InputField fullWidth variant='outlined' placeholder='00000 00000' value={this.state.phoneNumber} data-test-id="phoneNumber"
              onChange={(event) => {
                this.onValueChange('phoneNumber', event.target.value);
              }} 
              onBlur={(event) => {
                this.onValueChange('phoneNumber', event.target.value);
              }}
              />
               <ErrorBox >{this.state.phoneNumberError}</ErrorBox>
              </div>
          </Box>
          <Typography style={webStyles.title}>{configJSON.role}</Typography>
          <DropDown fullWidth variant='outlined' placeholder='Select role' IconComponent={KeyboardArrowDown} data-test-id="role"
            value={this.state.role}
            onChange={(event) => {
              this.onValueChange('role', event.target.value);
            }}
            onBlur={(event) => {
              this.onValueChange('role', event.target.value);
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            style={{ color: this.checkColorCondition(this.state.role, 'Select role') }}
          >
            <MenuItem style={{ display: 'none' }} value='Select role'>Select role</MenuItem>
            {this.getDropdowns("roles").map((country:string) => (
              <MenuItem key={country} value={country}>{country}</MenuItem>
            ))}
          </DropDown>
          <Typography style={webStyles.title}>{configJSON.employee}</Typography>
          <InputField fullWidth variant='outlined' data-test-id="employee" placeholder='Enter number of employees'
            value={this.state.employee}
            onBlur={(event) => {
              this.onValueChange('employee', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('employee', event.target.value);
            }} />
           <ErrorBox>{this.state.employeeError}</ErrorBox>
  
          <Typography style={webStyles.title}>{configJSON.program}</Typography>
          <DropDown fullWidth variant='outlined' placeholder='Select program type' IconComponent={KeyboardArrowDown} data-test-id="program"
            onChange={(event) => {
              this.onValueChange('program', event.target.value);
            }}
            onBlur={(event) => {
              this.onValueChange('program', event.target.value);
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            value={this.state.program}
            style={{ color: this.checkColorCondition(this.state.program, 'Select program type') }}
          >
            <MenuItem style={{ display: 'none' }} value='Select program type'>Select program type</MenuItem>
            {this.getDropdowns("programType").map((country:string) => (
              <MenuItem key={country} value={country}>{country}</MenuItem>
            ))}
          </DropDown>
          <Typography style={webStyles.title}>{configJSON.capacity}</Typography>
          <InputField fullWidth variant='outlined' placeholder='Enter program capacity'
            value={this.state.capacity}
            data-test-id="capacity"
            onBlur={(event) => {
              this.onValueChange('capacity', event.target.value);
            }}
            onChange={(event) => {
              this.onValueChange('capacity', event.target.value);
            }} />
           <ErrorBox>{this.state.capacityError}</ErrorBox>

          <Typography style={webStyles.title}>{configJSON.aboutUs}</Typography>
          <DropDown fullWidth variant='outlined' placeholder='Select option' IconComponent={KeyboardArrowDown} data-test-id="aboutUs"
            onChange={(event) => {
              this.onValueChange('aboutUs', event.target.value);
            }}
            value={this.state.aboutUs}
            onBlur={(event) => {
              this.onValueChange('aboutUs', event.target.value);
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            style={{ color: this.checkColorCondition(this.state.aboutUs, 'Select option')}}
          >
            <MenuItem style={{ display: 'none' }} value='Select option'>Select option</MenuItem>
            {this.state.aboutUsList.map((country) => (
              <MenuItem key={country} value={country}>{country}</MenuItem>
            ))}
          </DropDown>
          <ContinueButtonBox style={ this.validateForm() ? {cursor:"pointer"} : {cursor:"not-allowed"} }>
          <Button  fullWidth data-test-id="submitBtn"
          disabled={!this.validateForm()}
            onClick={() => this.submitButton()}
            style={ this.validateForm() ? {...webStyles.continueButton , marginBottom: '96px'} : {...webStyles.continueButtonDisabled , marginBottom: '96px'} }
          >
            Create account
          </Button>
          </ContinueButtonBox>
        </FormBox>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isSnackbarOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <div style={webStyles.popup as React.CSSProperties}>
            Please fill all the fields
          </div>
        </Snackbar>
      </MainContainerBox>
      : <ContainerBox>
        <BackIconBox data-test-id="btnBackward" onClick={() => this.handleBackToRoleSelection()}>
        <ArrowBackIosIcon />
          <Typography style={webStyles.backIconTxt}>{configJSON.backTxt}</Typography>
        </BackIconBox>
        <WebLogoImage image={logo} style={{width:"260px" , height:"90px"}}/>
        <FormContainer>
          <PageHeading>{configJSON.substituteTeacherHeading}</PageHeading>

          <PageSubHeading>{configJSON.substituteTeacherSubHeading}</PageSubHeading>

          <ProfilePictureHeading>{configJSON.profilePictureTxt}</ProfilePictureHeading>

          <ProfileAvatarWrapper>
            <label htmlFor="image-upload" className="edit-icon">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <img src={editProfileIcon} alt="edit_profile_icon"/>
                }
                style={{cursor: 'pointer'}}
              >
                <ProfileAvatar src={this.state.profileImage ? URL.createObjectURL(this.state.profileImage) : "path/to/default/image"} alt="H" />
              </Badge>
            </label>
            <input data-test-id='setProfileImage' id="image-upload" type="file" accept="image/*" onChange={this.setProfileImage} style={{display: "none"}} />
          </ProfileAvatarWrapper>

          <InputsLabel>{configJSON.firstName}</InputsLabel>
          <InputField
            data-test-id="firstName"
            placeholder='Enter first name'
            variant='outlined'
            onBlur={(event) => {
              this.onValueChange('firstName', event.target.value);
            }}
            fullWidth 
            value={this.state.firstName}
            onChange={(event) => { this.onValueChange('firstName', event.target.value) } }
          />
          <ErrorBox>{this.state.firstNameError}</ErrorBox>

          <InputsLabel>{configJSON.secondName}</InputsLabel>
          <InputField 
            data-test-id="lastName"
            placeholder='Enter last name'
            variant='outlined' 
            fullWidth 
            onBlur={(event) => {
              this.onValueChange('lastName', event.target.value);
            }}
            value={this.state.lastName}
            onChange={(event) => { this.onValueChange('lastName', event.target.value) } } 
          />
          <ErrorBox>{this.state.lastNameError}</ErrorBox>

          <InputsLabel>{configJSON.workPhoneNumberTxt}</InputsLabel>
          <Box style={webStyles.phoneBox}>
            <DropDown 
              data-test-id="phoneCode" 
              variant='outlined' 
              style={{ width: "100%"  , maxWidth:"113px" }}
              value={this.state.phoneCode} 
              onBlur={(event) => {
                this.onValueChange('phoneCode', event.target.value);
              }}
              IconComponent={KeyboardArrowDown} 
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
              onChange={(event) => {
                this.onValueChange('phoneCode', event.target.value);
              }}
              >
              {this.state.phoneCodeList.map((code) => (
                <MenuItem key={code} value={code}>{code}</MenuItem>
              ))}
            </DropDown>
            <Box style={{...webStyles.phoneBox , flexDirection:'column' , gap:'0' ,width:"100%"}}>
            <InputField 
              data-test-id="phoneNumber"
              variant='outlined' 
              placeholder='00000 00000' 
              onBlur={(event) => {
                this.onValueChange('phoneNumber', event.target.value);
              }}
              fullWidth 
              value={this.state.phoneNumber} 
              onChange={(event) => { 
                
                const inputValue = event.target.value;
                if (inputValue.length <= 10) { 
                    this.onValueChange('phoneNumber', inputValue);
                }
              }
            }  
            />
            <ErrorBox>{this.state.phoneNumberError}</ErrorBox>
            </Box>
          </Box>

          <InputsLabel>{configJSON.street}</InputsLabel>
          <InputField 
            data-test-id="street"
            placeholder='Enter street address'
            onBlur={(event) => {
              this.onValueChange('street', event.target.value);
            }}
            variant='outlined'
            fullWidth
            value={this.state.street}
            onChange={(event) => { this.onValueChange('street', event.target.value) } } 
          />
          <ErrorBox>{this.state.streetError}</ErrorBox>

          <InputsLabel>{configJSON.city}</InputsLabel>
          <InputField 
            data-test-id="city"
            placeholder='Enter city'
            variant='outlined' 
            onBlur={(event) => {
              this.onValueChange('city', event.target.value);
            }}
            fullWidth 
            value={this.state.city}
            onChange={(event) => { this.onValueChange('city', event.target.value) } }
           />
           <ErrorBox>{this.state.cityError}</ErrorBox>

          <InputsLabel>{configJSON.state}</InputsLabel>
          <InputField 
            data-test-id="state"
            placeholder='Enter state'
            variant='outlined' 
            onBlur={(event) => {
              this.onValueChange('state', event.target.value);
            }}
            fullWidth 
            value={this.state.state}
            onChange={(event) => { this.onValueChange('state', event.target.value) } }
           />
           <ErrorBox>{this.state.stateError}</ErrorBox>

          <InputsLabel>{configJSON.zipcode}</InputsLabel>
          <InputField 
            data-test-id="zipCode"
            placeholder='Enter zip code'
            variant='outlined' 
            onBlur={(event) => {
              this.onValueChange('zipCode', event.target.value);
            }}
            fullWidth 
            value={this.state.zipCode}
            onChange={(event) => { this.onValueChange('zipCode', event.target.value) } } 
          />
          <ErrorBox>{this.state.zipCodeError}</ErrorBox>

          <InputsLabel>{configJSON.Country}</InputsLabel>
          <DropDown 
            data-test-id="country"
            variant='outlined' 
            placeholder='Select country' 
            onBlur={(event) => {
              this.onValueChange('country', event.target.value);
            }}
            fullWidth 
            IconComponent={KeyboardArrowDown}
            value={this.state.country}
            onChange={(event) => { this.onValueChange('country', event.target.value) } }
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            style={{ color: this.checkColorCondition(this.state.country, 'Select country') }}
          >
            <MenuItem style={{ display: 'none' }} value='Select country'>{configJSON.selectOptionTxt}</MenuItem>
            {this.state.countryList.map((country) => (
              <MenuItem key={country} value={country}>{country}</MenuItem>
            ))}
          </DropDown>
          
          <CheckBoxList
            data-test-id="teacherCheckboxInputs"
            title={configJSON.preferredModalityTxt}
            options={this.state.preferredModalityOptions}
            onCheckBoxChange={(index) => this.handleMultiCheckBoxChange(index, 'preferredModality')}
            margin='16px'
            onBlurChange={(index) => this.handleMultiCheckBoxChange(index, 'preferredModality')}
          />

          <CheckBoxList
            data-test-id="teacherCheckboxInputs"
            title={configJSON.preferredAgeGrpTxt}
            options={this.state.preferredAgeGroupOptions}
            onCheckBoxChange={(index) => this.handleMultiCheckBoxChange(index, 'preferredAgeGroup')}
            margin='24px'
            onBlurChange={(index) => this.handleMultiCheckBoxChange(index, 'preferredModality')}
          />

          <CheckBoxList
            data-test-id="teacherCheckboxInputs"
            title={configJSON.preferredWorkingDays}
            options={this.state.preferredWorkingDaysOptions}
            onCheckBoxChange={(index) => this.handleMultiCheckBoxChange(index, 'preferredWorkingDays')}
            margin='24px'
            onBlurChange={(index) => this.handleMultiCheckBoxChange(index, 'preferredModality')}
          />

          <RadioList
            data-test-id="teacherRadios"
            title={configJSON.preferredTimeShiftTxt}
            options={this.state.preferredTimeShiftOptions}
            margin='24px'
            value={this.state.preferredTimeShift}
            handleChange={(event) => this.handleOptionChange(event, 'preferredTimeShift')}
          />

          <RadioList
            data-test-id="teacherRadios"
            title={configJSON.preferredNatureOfJobTxt}
            options={this.state.preferredNatureOfJobOptions}
            margin='24px'
            value={this.state.preferredNatureOfJob}
            handleChange={(event) => this.handleOptionChange(event, 'preferredNatureOfJob')}
          />

          <TravelMainBox>
            <TravelLeftBox>{configJSON.preferredTravelDistanceTxt}</TravelLeftBox>
            <TravelRightBox>
              <InputField  
                data-test-id="travelDistance"
                placeholder='Distance in miles'
                onBlur={(event) => {
                  this.onValueChange('travelDistance', event.target.value);
                }}
                variant='outlined' 
                fullWidth 
                value={this.state.travelDistance}
                onChange={(event) => { this.onValueChange('travelDistance', event.target.value) } } 
              />
            </TravelRightBox>
          </TravelMainBox>

          <RadioList
            data-test-id="teacherRadios"
            title={configJSON.documentSharingPreferenceTxt}
            options={this.state.documentSharingPreferenceOptions}
            margin='24px'
            value={this.state.documentSharingPreference}
            handleChange={(event) => this.handleOptionChange(event, 'documentSharingPreference')}
          />

          <UploadDocumentLabel>{configJSON.documentsTxt}</UploadDocumentLabel>
          <DocumentDistribution
            data-test-id="documentDistribution"
            id=''
            navigation=''
            filename={this.state.uploadResume}
            defaultName={configJSON.uploadResumeTxt}
            onChangeName={'uploadResume'}
            handleDocumentChange={this.handleDocumentChange}
          />

          <DocumentDistribution
            data-test-id="documentDistribution"
            id=''
            navigation=''
            filename={this.state.uploadLetterOfInterest}
            defaultName={configJSON.lettersOfInterestTxt}
            onChangeName={'uploadLetterOfInterest'}
            handleDocumentChange={this.handleDocumentChange}
          />

          <DocumentDistribution
            id=''
            data-test-id="documentDistribution"
            navigation=''
            filename={this.state.uploadCredentials}
            defaultName={configJSON.credentialsTxt  }
            onChangeName={'uploadCredentials'}
            handleDocumentChange={this.handleDocumentChange}
          />
          
          <DocumentDistribution
            id=''
            data-test-id="documentDistribution"
            navigation=''
            filename={this.state.uploadTranscripts}
            defaultName={configJSON.transcriptsTxt}
            onChangeName={'uploadTranscripts'}
            handleDocumentChange={this.handleDocumentChange}
          />

          <DocumentDistribution
            id=''
            data-test-id="documentDistribution"
            navigation=''
            filename={this.state.uploadNotesPSSA}
            defaultName={configJSON.notesPSSATxt}
            onChangeName={'uploadNotesPSSA'}
            handleDocumentChange={this.handleDocumentChange}
          />

          <DocumentDistribution
            data-test-id="documentDistribution"
            id=''
            navigation=''
            filename={this.state.uploadLetterReffEmail}
            defaultName={configJSON.letterReferenceEmailTxt}
            onChangeName={'uploadLetterReffEmail'}
            handleDocumentChange={this.handleDocumentChange}
          />

          <InputsLabelAboutUs>{configJSON.aboutUs}</InputsLabelAboutUs>
          <DropDown 
            data-test-id="aboutUs"
            variant='outlined' 
            placeholder='Select option' 
            IconComponent={KeyboardArrowDown} 
            fullWidth
            onBlur={(event) => {
              this.onValueChange('aboutUs', event.target.value);
            }}
            value={this.state.aboutUs}
            onChange={(event) => { this.onValueChange('aboutUs', event.target.value) } }
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            style={{ color: this.checkColorCondition(this.state.aboutUs, 'Select option') }}
          >
            <MenuItem style={{ display: 'none' }} value='Select option'>{configJSON.selectOptionTxt}</MenuItem>
            {this.state.aboutUsList.map((option) => (
              <MenuItem style={{color: '#1E293B'}} key={option} value={option}>{option}</MenuItem>
            ))}
          </DropDown>

          <ContinueButtonBox>
              <button data-test-id="submitBtn" disabled={!this.checkIsFormValid()} onClick={this.continueForm} style={ this.checkIsFormValid() ? webStyles.continueButton : webStyles.continueButtonDisabled } >{configJSON.continueTxt}</button>
          </ContinueButtonBox>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.isSnackbarOpen}
            autoHideDuration={5000}
            onClose={this.closeSnackbar}
          >
            <div style={webStyles.popup as React.CSSProperties}>
              {configJSON.somethingWentWrong}
            </div>
          </Snackbar>
        </FormContainer>
      </ContainerBox>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
